<template>
  <LazyHydrationWrapper
    :when-visible="{ rootMargin: '50px' }"
  >
    <component
      :is="blockComponent"
      v-if="blockComponent && !hideNicotineWarning"
      :block="block"
      :is-mobile="isMobile"
      :data-epi-edit="epiEditProperty"
      :is-edit-mode="isEditOrPreviewMode"
      :text-color="textColor"
    ></component>
  </LazyHydrationWrapper>
</template>
<script setup lang="ts">
import { storeToRefs } from 'pinia';
import { useUiStore } from '~/store/ui';
import { LazyHydrationWrapper } from 'vue3-lazy-hydration';
const uiStore = useUiStore();
const { isMobile } = storeToRefs(uiStore);

const props = defineProps<{
  block: any,
  textColor?: string,
  epiEditProperty?: string,
  isNicotineFree?: boolean,
}>();

const blockComponent = computed(() => {
  if (props.block.__typename) {
    const resolved = resolveComponent(props.block.__typename);
    if (typeof resolved === 'object') {
      return resolved;
    }
  }

  return false;
});

const hideNicotineWarning = computed(() => {
  if(props.block.__typename == "WarningBlock"){
    return props.isNicotineFree;
  }
  return false;
});

const isEditOrPreviewMode = computed(() => {
  const route = useRoute();
  return route.query.epieditmode === 'false' || route.query.epieditmode === 'true';
});
</script>
